var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" Portfolio Information")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);

}]

export { render, staticRenderFns }